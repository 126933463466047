import "./Partners.css";
import CarouselPartnerLogo from "./CarouselPartnerLogo";

export default function Partners() {
  return (
    <section id="partners" className="home-partners">
      <div className="home__inner">
        <h2 className="home-partners__title pc">
          기후 위기를 해결하고 있는 파트너들
        </h2>
        <h2 className="home-partners__title mobile">
          기후 위기를
          <br /> 함께 해결하고 있는 파트너들
        </h2>
        <CarouselPartnerLogo>
          <li className="logo_1">
            <img
              src={`${process.env.PUBLIC_URL}/images/home/partner_logo_1.png`}
              alt="현대차 정몽구 재단"
            />
          </li>
          <li className="logo_2">
            <img
              src={`${process.env.PUBLIC_URL}/images/home/partner_logo_2.png`}
              alt="MYSC"
            />
          </li>
          <li className="logo_3">
            <img
              src={`${process.env.PUBLIC_URL}/images/home/partner_logo_3.png`}
              alt="전국시민발전 협동조합연합회"
            />
          </li>
          <li className="logo_4">
            <img
              src={`${process.env.PUBLIC_URL}/images/home/partner_logo_4.png`}
              alt="경기 에너지 협동조합"
            />
          </li>
          <li className="logo_5">
            <img
              src={`${process.env.PUBLIC_URL}/images/home/partner_logo_5.png`}
              alt="고양시 시민햇빛발전 협동조합"
            />
          </li>
          <li className="logo_6">
            <img
              src={`${process.env.PUBLIC_URL}/images/home/partner_logo_6.png`}
              alt="안산시민 햇빛발전 협동조합"
            />
          </li>
          <li className="logo_7">
            <img
              src={`${process.env.PUBLIC_URL}/images/home/partner_logo_7.png`}
              alt="안양 군포 의왕 시민 햇빛발전 사화적협동조합"
            />
          </li>
          <li className="logo_8">
            <img
              src={`${process.env.PUBLIC_URL}/images/home/partner_logo_8.png`}
              alt="오산시민 햇빛발전 사회적협동조합"
            />
          </li>
          <li className="logo_1">
            <img
              src={`${process.env.PUBLIC_URL}/images/home/partner_logo_1.png`}
              alt="현대차 정몽구 재단"
            />
          </li>
          <li className="logo_2">
            <img
              src={`${process.env.PUBLIC_URL}/images/home/partner_logo_2.png`}
              alt="MYSC"
            />
          </li>
          <li className="logo_3">
            <img
              src={`${process.env.PUBLIC_URL}/images/home/partner_logo_3.png`}
              alt="전국시민발전 협동조합연합회"
            />
          </li>
          <li className="logo_4">
            <img
              src={`${process.env.PUBLIC_URL}/images/home/partner_logo_4.png`}
              alt="경기 에너지 협동조합"
            />
          </li>
        </CarouselPartnerLogo>
      </div>
    </section>
  );
};