import { useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Banner from "./Banner/Banner";
import ServiceDescription from "./ServiceDescription/ServiceDescription";
import Benefit from "./Benefit/Benefit";
import Calculation from "./Calculation/Calculation";
import MonthlyFee from "./MonthlyFee/MonthlyFee";
import SubscribeForm from "./SubscribeForm/SubscribeForm";
import Improvement from "./Improvement/Improvement";
import Process from "./Process/Process";
import Detail from "./Detail/Detail";
import Report from "./Report/Report";
import Partners from "./Partners/Partners";
import "./Home.css";

export default function Home() {
  const [isSubscribeActive] = useState(false);
  const [isPartnerActive] = useState(false);
  const [isReportActive] = useState(false);

  return (
    <>
      <Header
        isSubscribeActive={isSubscribeActive}
        isPartnerActive={isPartnerActive}
        isReportActive={isReportActive}
      />
      <main className="home">
        <Banner />
        <ServiceDescription />
        <Benefit />
        <Calculation />
        <MonthlyFee />
        <SubscribeForm />
        <Improvement />
        <Process />
        <Detail />
        <Report />
        <Partners />
      </main>
      <Footer />
    </>
  );
}
