import "./Report.css";

export default function Report() {
  return (
    <section id="report" className="home-report">
      <div className="home-report-inner home__inner">
        <div className="home-report__title">
          <h2>재생에너지 사용 홍보 수단 제공</h2>
        </div>
        <div className="home-report__content">
          <div className="report">
            <div>
              <h3>월간 탄소감축 리포트</h3>
              <p>구독 상세 내용과 성과를 한눈에 알도록</p>
              <p>기업 맞춤 리포트를 작성해 드립니다.</p>
            </div>
          </div>
          <a href="https://connect.60hz.io/members/1000" target="_blank">
            <div className="badge">
              <div>
                <h3>재생에너지 사용 인증 마크</h3>
                <p>사이트에 삽입해 우리 기업의 탄소감축 활동을 알려보세요.</p>
                <p>클릭하면 구독 성과 설명 페이지로 연결됩니다.</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
}
