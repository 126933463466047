export default class Calculation {
  // 전력 단가
  UNIT_PRICE = 5000;
  // 구독기간
  ONE_MONTH = 1;
  TOW_MONTH = 2;
  THREE_MONTH = 3;
  // 최대 지원금
  MAXIMUM_GRANT_LIMIT = 1000000;

  constructor(pyeong) {
    this.pyeong = pyeong;
  }

  // 월 구독료
  monthlySubscribeFee() {
    return this.pyeong * this.UNIT_PRICE;
  }
  //구독 기간
  subscribePeriod() {
    if (
      this.monthlySubscribeFee() * this.THREE_MONTH <=
      this.MAXIMUM_GRANT_LIMIT
    ) {
      return this.THREE_MONTH;
    } else if (
      this.monthlySubscribeFee() * this.TOW_MONTH <=
      this.MAXIMUM_GRANT_LIMIT
    ) {
      return this.TOW_MONTH;
    } else if (
      this.monthlySubscribeFee() * this.ONE_MONTH <=
      this.MAXIMUM_GRANT_LIMIT
    ) {
      return this.ONE_MONTH;
    } else {
      return this.ONE_MONTH;
    }
  }
  //최대 지원금
  maximumGrant() {
    if (
      this.monthlySubscribeFee() * this.subscribePeriod() >=
      this.MAXIMUM_GRANT_LIMIT
    ) {
      return this.MAXIMUM_GRANT_LIMIT;
    } else {
      return this.monthlySubscribeFee() * this.subscribePeriod();
    }
  }
}
