import "./Improvement.css";

export default function Improvement() {
  return (
    <section className="home-improvement">
      <div className="home-improvement__title">
        <div className="home__inner">
          <h2>
            혁신적인
            <br /> 재생에너지
            <br /> 이용법
          </h2>
          <div className="line_img">
            <img
              className="pc"
              src={`${process.env.PUBLIC_URL}/images/home/improvement_line.png`}
              alt="에너지 아이콘"
            />
            <img
              className="tablet"
              src={`${process.env.PUBLIC_URL}/images/home/mprovement_line_tablet.png`}
              alt="에너지 아이콘"
            />
          </div>
          <div className="text">
            <p>몇 가지 정보 입력으로</p>
            <p>사업장의 전력사용량만큼만</p>
            <p>친환경 재생에너지로 전환되는 것</p>
            <p>월간햇빛바람에서는 가능합니다.</p>
          </div>
        </div>
      </div>
      <div className="home-improvement__content">
        <div className="home__inner">
          <div className="minus">
            <div>
              <h3>
                재생에너지를
                <br />
                사용하기가 어려웠습니다.
              </h3>
              <ul>
                <li>
                  <span>복잡한 시스템 가입 절차</span>
                </li>
                <li>
                  <span>변동이 심한 가격</span>
                </li>
              </ul>
              <ul>
                <li>
                  <span>발전소 건설 환경 모름</span>
                </li>
                <li>
                  <span>대용량 위주 판매</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="plus">
            <div>
              <h3>
                재생에너지
                <br />
                사용법을 바꿔나갑니다.
              </h3>
              <ul>
                <li>
                  <span>시스템 가입 대행</span>
                </li>
                <li>
                  <span>시민이 만든 발전소와 계약</span>
                </li>
              </ul>
              <ul>
                <li>
                  <span>월정액 상품</span>
                </li>
                <li>
                  <span>10평 기업도 사용 가능</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
