const companyInfo = [
  {
    id: 1000,
    company: "식스티헤르츠",
    conversionRate: "100%",
    startDate: "2022년 12월 ~",
    companyType: "사무실",
    carbonEffect: "200",
    treeEffect: "32",
    totalTree: "64",
    environmentalEffect: "5,500",
    powerPlantDescription_1:
      "식스티헤르츠 기업의 구독 재생에너지는 가나다라 발전소에서 만들어졌습니다. 가나다라 발전소는 기존 건물 지붕 위에 지었습니다.",
    powerPlantDescription_2:
      "환경을 해치지 않고 건설한 발전소라는 이야기입니다. 또 구독기업과 같은 수도권에 위치해 전기를 보내는데 적은 환경 오염이 발생하고 있습니다.",
    powerPlantName: "경기도민 햇빛발전소 1호기",
    provisionCooperative: "경기에너지협동조합",
    powerPlantAdd: "경기도 수원시 팔달구 인계동",
    commercialOperationStartDate: "2021.11.21",
    electricCapacity: "99.9kW",
  },
  {
    id: 1,
    company: "라잇루트",
    conversionRate: "100%",
    startDate: "2022년 11월 30일 ~",
    companyType: "사무실",
    carbonEffect: "228",
    treeEffect: "281",
    totalTree: "1,124",
    environmentalEffect: "6,700",
    powerPlantDescription_1:
      "라잇루트의 구독 재생에너지는 경기도민 햇빛발전소 1호기에서 만들어졌습니다. 경기도민 햇빛발전소 1호기는 경기아트센토 옥상 위에 지었습니다. 환경을 해치지 않고 건설한 발전소라는 이야기입니다.",
    powerPlantDescription_2: "",
    powerPlantName: "경기도민 햇빛발전소 1호기",
    provisionCooperative: "경기에너지협동조합",
    powerPlantAdd: "경기도 수원시 팔달구 인계동",
    commercialOperationStartDate: "2021.11.21",
    electricCapacity: "99.9kW",
  },
  {
    id: 2,
    company: "와들",
    conversionRate: "100%",
    startDate: "2022년 11월 30일 ~",
    companyType: "사무실",
    carbonEffect: "100",
    treeEffect: "124",
    totalTree: "496",
    environmentalEffect: "3,000",
    powerPlantDescription_1:
      "와들의 구독 재생에너지는 경기도민 햇빛발전소 1호기에서 만들어졌습니다. 경기도민 햇빛발전소 1호기는 경기아트센토 옥상 위에 지었습니다. 환경을 해치지 않고 건설한 발전소라는 이야기입니다.",
    powerPlantDescription_2: "",
    powerPlantName: "경기도민 햇빛발전소 1호기",
    provisionCooperative: "경기에너지협동조합",
    powerPlantAdd: "경기도 수원시 팔달구 인계동",
    commercialOperationStartDate: "2021.11.21",
    electricCapacity: "99.9kW",
  },
  {
    id: 3,
    company: "컨티뉴",
    conversionRate: "100%",
    startDate: "2022년 12월 05일 ~",
    companyType: "공장",
    carbonEffect: "586",
    treeEffect: "723",
    totalTree: "2,169",
    environmentalEffect: "17,100",
    powerPlantDescription_1:
      "컨티뉴가 구독하는 재생에너지는 경기도민 햇빛발전소 1호기에서 만들어졌습니다. 경기도민 햇빛발전소 1호기는 경기아트센터 옥상 위에 지었습니다. 시민들이 환경을 해치지 않고 건설한 발전소라는 이야기입니다.",
    powerPlantDescription_2: "",
    powerPlantName: "경기도민 햇빛발전소 1호기",
    provisionCooperative: "경기에너지협동조합",
    powerPlantAdd: "경기도 수원시 팔달구 인계동",
    commercialOperationStartDate: "2021.11.21",
    electricCapacity: "99.9kW",
  },
  {
    id: 4,
    company: "체리빈커피",
    conversionRate: "100%",
    startDate: "2022년 12월 05일 ~",
    companyType: "공장",
    carbonEffect: "297",
    treeEffect: "367",
    totalTree: "1,101",
    environmentalEffect: "8,700",
    powerPlantDescription_1:
      "체리빈커피는 공정무역재단 아름다운커피의 투자로 론칭하고, 서스테이너블커피랩에서 운영하고 있는 공유 로스터리 브랜드입니다. 체리를 따고 빈을 볶아 커피를 내리기까지, 세상과 함께하는 올바른 커피생활문화를 지향합니다.",
    powerPlantDescription_2:
      "체리빈커피가 구독하는 재생에너지는 경기도민 햇빛발전소 1호기에서 만들어졌습니다. 경기도민 햇빛발전소 1호기는 경기아트센터 옥상 위에 지었습니다. 시민들이 환경을 해치지 않고 건설한 발전소라는 이야기입니다.",
    powerPlantName: "경기도민 햇빛발전소 1호기",
    provisionCooperative: "경기에너지협동조합",
    powerPlantAdd: "경기도 수원시 팔달구 인계동",
    commercialOperationStartDate: "2021.11.21",
    electricCapacity: "99.9kW",
  },
  {
    id: 5,
    company: "쿨베어스",
    conversionRate: "100%",
    startDate: "2022년 12월 09일 ~",
    companyType: "사무실",
    carbonEffect: "165",
    treeEffect: "203",
    totalTree: "609",
    environmentalEffect: "4,800",
    powerPlantDescription_1:
      "쿨베어스가 구독하는 재생에너지는 경기도민 햇빛발전소 1호기에서 만들어졌습니다. 경기도민 햇빛발전소 1호기는 경기아트센터 옥상 위에 지었습니다. 시민들이 환경을 해치지 않고 건설한 발전소라는 이야기입니다.",
    powerPlantDescription_2:
      "",
    powerPlantName: "경기도민 햇빛발전소 1호기",
    provisionCooperative: "경기에너지협동조합",
    powerPlantAdd: "경기도 수원시 팔달구 인계동",
    commercialOperationStartDate: "2021.11.21",
    electricCapacity: "99.9kW",
  },
  {
    id: 6,
    company: "리필리",
    conversionRate: "100%",
    startDate: "2022년 12월 22일 ~",
    companyType: "공장",
    carbonEffect: "221",
    treeEffect: "273",
    totalTree: "819",
    environmentalEffect: "6,500",
    powerPlantDescription_1:
      "리필리가 구독하는 재생에너지는 경기도민 햇빛발전소 1호기에서 만들어졌습니다. 경기도민 햇빛발전소 1호기는 경기아트센터 옥상 위에 지었습니다. 시민들이 환경을 해치지 않고 건설한 발전소라는 이야기입니다.",
    powerPlantDescription_2:
      "",
    powerPlantName: "경기도민 햇빛발전소 1호기",
    provisionCooperative: "경기에너지협동조합",
    powerPlantAdd: "경기도 수원시 팔달구 인계동",
    commercialOperationStartDate: "2021.11.21",
    electricCapacity: "99.9kW",
  },
  {
    id: 7,
    company: "위미트",
    conversionRate: "100%",
    startDate: "2023년 01월 17일 ~",
    companyType: "사무실",
    carbonEffect: "125",
    treeEffect: "155",
    totalTree: "310",
    environmentalEffect: "3,700",
    powerPlantDescription_1:
      "위미트가 구독하는 재생에너지는 경기도민 햇빛발전소 1호기에서 만들어졌습니다. 경기도민 햇빛발전소 1호기는 경기아트센터 옥상 위에 지었습니다. 시민들이 환경을 해치지 않고 건설한 발전소라는 이야기입니다.",
    powerPlantDescription_2:
      "",
    powerPlantName: "경기도민 햇빛발전소 1호기",
    provisionCooperative: "경기에너지협동조합",
    powerPlantAdd: "경기도 수원시 팔달구 인계동",
    commercialOperationStartDate: "2021.11.21",
    electricCapacity: "99.9kW",
  },
];

export default companyInfo;
