import { useState } from "react";
import "./Detail.css";

export default function Detail() {
  const [dropShow, isDropShow] = useState({
    drop1: false,
    drop2: false,
    drop3: false,
  });
  
  const handleDropClick = (value) => {
    isDropShow({
      ...dropShow,
      [value]: !dropShow[value],
    });
  };

  return (
    <section id="service-detail" className="home-service-detail">
      <div className="home__inner">
        <h2>재생에너지 사용 자세히 알아보기</h2>
        <div className="home-service-detail__content">
          <ul>
            <li>
              <div className="title" onClick={() => handleDropClick("drop1")}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/home/detail_q1.png`}
                  alt="보기"
                />
                <h3>월간햇빛바람의 재생에너지 사용법</h3>
                <div className={`btn ${dropShow.drop1 ? "show" : ""}`}></div>
              </div>
              <div className={`content ${dropShow.drop1 ? "show" : ""}`}>
                <div className="text">
                  <p>
                    월간햇빛바람은 기업이 사용한 전력을 재생에너지로 전환할 수
                    있도록, 재생에너지 공급인증서(REC,Renewable Energy
                    Certificate)를 구입해 제공합니다. REC는 재생에너지 1MWh가
                    생산될 때마다 발행되며, REC를 구매하면 재생에너지 사용으로
                    인정받습니다.
                  </p>
                </div>
                <img
                  src={`${process.env.PUBLIC_URL}/images/home/service_detail_1.png`}
                  alt="REC 사용법 설명"
                />
              </div>
            </li>
            <li>
              <div className="title" onClick={() => handleDropClick("drop3")}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/home/detail_q2.png`}
                  alt="보기"
                />
                <h3>글로벌 기업들의 트렌드: 재생에너지로 전환(RE100)</h3>
                <div className={`btn ${dropShow.drop3 ? "show" : ""}`}></div>
              </div>
              <div className={`content ${dropShow.drop3 ? "show" : ""}`}>
                <p className="text">
                  지속 가능한 지구를 위해 재생에너지로 전환하는 기업이 늘고
                  있습니다. 애플, 구글, 나이키 등 유명 글로벌 기업들은
                  <strong>‘RE100(Renewable Electricity 100%)’</strong>에 참여해
                  사용하는 전력을 재생에너지로 바꿔가고 있습니다. RE100은
                  기업들이 2050년까지 자발적으로 사용하는 전력을 100%를
                  재생에너지로 바꾸자는 캠페인으로 2014년 영국의 NGO '더
                  클라이밋 그룹'이 시작했습니다.
                </p>
                <div className="img_box pc">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/home/service_detail_3_1.png`}
                    alt="트렌드를 주도하는 380여 글로벌 기업들은 이미 RE100에 참여해 재생에너지로 전환중입니다."
                  />
                  <img
                    src={`${process.env.PUBLIC_URL}/images/home/service_detail_3_2.png`}
                    alt="월간햇빛바람을 구독하면 RE100을 이행할 수 있습니다."
                  />
                </div>
                <div className="img_box mobile">
                  <div>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/home/service_detail_3_3.png`}
                      alt="트렌드를 주도하는 380여 글로벌 기업들은 이미 RE100에 참여해 재생에너지로 전환중입니다."
                    />
                    <img
                      src={`${process.env.PUBLIC_URL}/images/home/service_detail_3_4.png`}
                      alt="월간햇빛바람을 구독하면 RE100을 이행할 수 있습니다."
                    />
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="title" onClick={() => handleDropClick("drop2")}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/home/detail_q3.png`}
                  alt="RE100 보기"
                />
                <h3>한국의 재생에너지 사용법(K-RE100)</h3>
                <div className={`btn ${dropShow.drop2 ? "show" : ""}`}></div>
              </div>
              <div className={`content ${dropShow.drop2 ? "show" : ""}`}>
                <p className="text">
                  한국형 RE100(K-RE100)은 국내 기업들이 RE100에 가입하거나,
                  재생에너지를 사용하는 것을 돕기 위해 만들어진 제도입니다.
                  K-RE100에는 REC을 포함해 재생에너지를 사용하는 방법이 5가지가
                  있습니다. 그러나 REC가 가장 합리적인 재생에너지 사용
                  방법입니다. 녹색프리미엄은 더블카운팅 이슈가 있습니다. PPA는
                  장기간 계약을 해야해 비용도 비싸고 과정도 복잡합니다.
                  재생에너지 발전소 직접 건설이나 지분 참여도 비용이 많이 들고,
                  장기간 사용해야해 위험 부담이 높습니다.
                </p>
                <div className="img_box">
                  <div>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/home/service_detail_2_1.png`}
                      alt="1.녹색 프리미엄"
                    />
                  </div>
                  <div>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/home/service_detail_2_2.png`}
                      alt="2.REC 구매"
                    />
                  </div>
                  <div>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/home/service_detail_2_3.png`}
                      alt="3.PPA 계약(직접, 제3자)"
                    />
                  </div>
                  <div>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/home/service_detail_2_4.png`}
                      alt="4.직접 건설 5.지분 참여"
                    />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};
