import './ServiceDescription.css';

export default function ServiceDescription() {
  return (
    <section className="home-service-description">
      <div className="home-service-description__inner home__inner">
        <div className="title action">
          <p>전문 지식이 없어도</p>
          <p>작은 기업이어도</p>
          <p>간단한 과정으로</p>
        </div>
        <div className="home-description__content">
          <ul>
            <li>
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/images/home/section2_2.png`}
                  alt="에너지 아이콘"
                />
              </div>
              <p>사업장 전력을 재생에너지로 전환</p>
            </li>
            <li>
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/images/home/suggestion_1.png`}
                  alt="전력 아이콘"
                />
              </div>
              <p>소량도 구독 가능</p>
            </li>
            <li>
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/images/home/section2_3.png`}
                  alt="인증서 아이콘"
                />
              </div>
              <p>인증서 발급</p>
            </li>
            <li>
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/images/home/section2_4.png`}
                  alt="나무 아이콘"
                />
              </div>
              <p>탄소감축 활동</p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};