import { useParams } from "react-router-dom";
import "./Members.css";
import PageNotFound from "../PageNotFound/PageNotFound";
import link from "../../data/link";

export default function Members({ companyInfo }) {
  let { id } = useParams();

  let index = companyInfo.findIndex((el) => {
    return el.id == Number(id);
  });

  return (
    <>
      {index === -1 ? (
        <PageNotFound />
      ) : (
        <div className="member-box">
          <div className="members">
            <div className="title">
              <div className="inner">
                <p className="sub-title">
                  기업이 지구에 미치는 영향을 생각합니다.
                </p>
                <h2>
                  재생에너지 사용으로 증명하는
                  <br /> {companyInfo[index].company}의 노력
                </h2>
                <p className="date">{companyInfo[index].startDate}</p>
              </div>
            </div>
            <section className="section1">
              <div className="inner">
                <img
                  src={`${process.env.PUBLIC_URL}/images/company/id${companyInfo[index].id}_first-gragh.png`}
                  alt="재생에너지 사용량 그래프"
                />
              </div>
            </section>
            <section className="section2">
              <div className="inner">
                <ul>
                  <li>
                    <p>
                      {companyInfo[index].carbonEffect}kg
                      <img
                        src={`${process.env.PUBLIC_URL}/images/members/section2_arrow_down.png`}
                        alt="화살표"
                      />
                    </p>
                    <p>이번 달 지구내 탄소감축 기여 예상량</p>
                  </li>
                  <li>
                    <p>
                      {companyInfo[index].treeEffect}그루
                      <img
                        src={`${process.env.PUBLIC_URL}/images/members/section2_arrow_up.png`}
                        alt="화살표"
                      />
                    </p>
                    <p>이번 달 나무 심은 효과 예상량</p>
                  </li>
                  <li>
                    <p>
                      {companyInfo[index].environmentalEffect}원
                      <img
                        src={`${process.env.PUBLIC_URL}/images/members/section2_arrow_down.png`}
                        alt="화살표"
                      />
                    </p>
                    <p>이번 달 탄소배출 저감분 예상 효과</p>
                  </li>
                </ul>
              </div>
            </section>
            <section className="section3">
              <div className="inner">
                <h3>
                  지금까지 총 {companyInfo[index].totalTree}그루의 나무를 심은
                  탄소 감축 효과를 내고 있습니다.
                </h3>
                <img
                  src={`${process.env.PUBLIC_URL}/images/company/id${companyInfo[index].id}_effect-graph.png`}
                  alt="나무심은 효과 그래프"
                />
              </div>
            </section>
            <section className="section4">
              <div className="inner">
                <div className="left-box">
                  <h3>
                    착한 전기 사용,
                    <br />
                    차츰 줄어가는 탄소 발자국
                  </h3>
                  <div>
                    <div>
                      <p>
                        {companyInfo[index].powerPlantDescription_1}
                        <br />
                        {companyInfo[index].powerPlantDescription_2}
                      </p>
                    </div>
                    <div>
                      <h4>
                        월간햇빛바람이란
                        <a href={link.connectURL}>
                          <span>자세히 알아보기</span>
                        </a>
                      </h4>
                      <p>
                        시민들이 만든 재생에너지로 구독 기업의 사업장 전력을
                        환경을 해치지 않는 착한 에너지로 전환해주는 ‘재생에너지
                        월 구독 서비스’입니다.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="right-box">
                  <div>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/company/power_plant.png`}
                      alt="발전소 정보"
                    />
                    <div className="text">
                      <dl>
                        <dt>발전소 이름</dt>
                        <dd>{companyInfo[index].powerPlantName}</dd>
                      </dl>
                      <dl>
                        <dt>제공 협동조합</dt>
                        <dd>{companyInfo[index].provisionCooperative}</dd>
                      </dl>
                      <dl>
                        <dt>발전소 주소</dt>
                        <dd>{companyInfo[index].powerPlantAdd}</dd>
                      </dl>
                      <dl>
                        <dt>상업운전 시작일</dt>
                        <dd>
                          {companyInfo[index].commercialOperationStartDate}
                        </dd>
                      </dl>
                      <dl>
                        <dt>용량</dt>
                        <dd>{companyInfo[index].electricCapacity}</dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <footer>
              <div className="inner">
                <a
                  className="footer__logo"
                  href={link.connectURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="logo"
                    src={`${process.env.PUBLIC_URL}/images/monthly_logo.svg`}
                    alt="월간햇빛바람"
                  />
                </a>
                <div className="text-box">
                  <div>
                    <h3>식스티헤르츠</h3>
                    <ul>
                      <li>
                        <a
                          className="home-link"
                          target="_blank"
                          href={link.sixtyURL}
                          rel="noopener noreferrer"
                        >
                          {link.sixtyURL}
                        </a>
                      </li>
                      <li>사업자등록번호: 878-88-01768</li>
                      <li>대표: 김종규</li>
                      <li>04538 서울시 중구 명동길 73 6층</li>
                    </ul>
                  </div>
                  <div>
                    <h3>Contact</h3>
                    <ul>
                      <li>
                        <a
                          className="mail-to"
                          target="_blank"
                          href={`mailto:${link.inquiryEmail}`}
                          rel="noopener noreferrer"
                        >
                          {link.inquiryEmail}
                        </a>
                      </li>
                      <li>02-6954-0068</li>
                    </ul>
                  </div>
                </div>
                <div className="badge">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/members/footer.png`} alt="배지 이미지"
                  />
                </div>
              </div>
            </footer>
          </div>
        </div>
      )}
    </>
  );
}
