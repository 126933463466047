const tutorial = [
  {
    id: 1,
    conversionRate: "50%",
    badgeImg: "green_energy_50.png",
    downloadImg: "https://docs.google.com/uc?export=download&id=1WI1gE7p0N213Utgp3M-0dP397OCThFW9",
    totalImg: "GE50.png",
  },
  {
    id: 2,
    conversionRate: "80%",
    badgeImg: "green_energy_80.png",
    downloadImg: "https://docs.google.com/uc?export=download&id=1u2-pQCfvwnJeD1cu8x2LOOwKlYoyv733",
    totalImg: "GE80.png",
  },
  {
    id: 3,
    conversionRate: "100%",
    badgeImg: "green_energy_100.png",
    downloadImg: "https://docs.google.com/uc?export=download&id=1pIITvtmHWSYzng2xQ2Y2awftsxQAow2p",
    totalImg: "GE100.png"
  }
]

export default tutorial;