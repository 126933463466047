import React from "react";
import styles from "./ModalContainer.module.css";

export default function ModalContainer({ children }) {
  return (
    <div className={styles.container}>
      <div className={styles.ovelay}></div>
      {children}
    </div>
  );
}
