import { useState } from "react";
import "./Calculation.css";
import Calculation from "./CalculationFn";
import link from "../../../data/link";
import {
  convertThreeDigitComma,
  validateOnlyNumbers,
} from "../../../utils/validationFn"

const HomeCalculation = ({}) => {
  const MIN_PYEONG_INPUT_VALUE = 10;
  const MAX_PYEONG_INPUT_VALUE = 999;
  const [pyeongValue, setPyeongValue] = useState("");
  const [limitMsg, setLimitMsg] = useState(false);
  const [calculationValue, setCalculationValue] = useState({
    monthlyFee: "",
    subscribePeriod: "",
    maximumGrant: "",
  });

  const calculation = new Calculation(pyeongValue);

  const onClickAreaSubmit = (e) => {
    e.preventDefault();
    if (
      pyeongValue < MIN_PYEONG_INPUT_VALUE ||
      pyeongValue > MAX_PYEONG_INPUT_VALUE
    ) {
      setLimitMsg(true);
    } else {
      setLimitMsg(false);
    }
    setCalculationValue({
      ...calculationValue,
      monthlySubscribeFee: convertThreeDigitComma(
        calculation.monthlySubscribeFee()
      ),
      subscribePeriod: calculation.subscribePeriod(),
      maximumGrant: convertThreeDigitComma(calculation.maximumGrant()),
    });
  };
  const onClickArea = (e) => {
    if (e.currentTarget.value === "0") {
      setPyeongValue("");
      setPyeongValue("");
      return false;
    }
    setPyeongValue(e.target.value);
    setPyeongValue(e.target.value);
  };

  return (
    <section className="home-subscribe-calculation">
      <div className="title">
        <div className="home__inner">
          <h3>
            최대 100만원 혜택
            <br />
            <span>
              첫 구독 신청 시 최대 3개월간 무료 구독
            </span>
          </h3>
          <p>
            무료 체험은 선착순 지원으로, 준비된 재생에너지 수량이 끝나면
            마감됩니다.
          </p>
        </div>
      </div>
      <div id="calculator" className="content">
        <div className="home__inner">
          <div>
            <h2>구독료 계산기</h2>
            <div className="form-box">
              <form onSubmit={onClickAreaSubmit}>
                <div>
                  <p>사업장 면적 (전용 면적 기준)</p>
                  <div className="area">
                    <input
                      type="text"
                      placeholder="평형 수"
                      maxLength={3}
                      value={pyeongValue}
                      onInput={validateOnlyNumbers}
                      onChange={(e) => onClickArea(e)}
                    />
                    <p>평</p>
                  </div>
                </div>
                <input
                  type={pyeongValue ? "submit" : "button"}
                  className="btn"
                  value={"계산"}
                />
              </form>
              {limitMsg ? (
                <div className="result-box text">
                  월간햇빛바람은 현재 {MIN_PYEONG_INPUT_VALUE}~
                  {MAX_PYEONG_INPUT_VALUE}평의 공간에 한해 서비스를 지원하고
                  있습니다.
                  <br /> 가입을 원하시면 {link.inquiryEmail}로 문의해주세요!
                </div>
              ) : (
                <div className="result-box">
                  <p>
                    월 구독료
                    {calculationValue.monthlySubscribeFee ? (
                      <span className="monthly-fee">
                        {calculationValue.monthlySubscribeFee}
                      </span>
                    ) : (
                      <img
                        src={`${process.env.PUBLIC_URL}/images/home/calculation_value.png`}
                        alt="월 구독료"
                      />
                    )}
                    원
                  </p>
                  <p>
                    구독 기간
                    {calculationValue.subscribePeriod ? (
                      <span>{calculationValue.subscribePeriod}</span>
                    ) : (
                      <img
                        src={`${process.env.PUBLIC_URL}/images/home/calculation_value.png`}
                        alt="구독 기간"
                      />
                    )}
                    개월 동안
                  </p>
                  <p>
                    최대
                    {calculationValue.maximumGrant ? (
                      <span>{calculationValue.maximumGrant}</span>
                    ) : (
                      <img
                        src={`${process.env.PUBLIC_URL}/images/home/calculation_value.png`}
                        alt="최대 지원금"
                      />
                    )}
                    원 지원
                  </p>
                </div>
              )}
            </div>
            <div className="text-box">
              <ul>
                <li>
                  * 위 금액은[사무실 - Type A /100% 전환율(VAT 포함)] 기준
                  구독료 예시입니다.
                </li>
                <li>
                  * 가입 가능한 사업장 종류: 사무실(Type A/B), 공장(Type A/B)
                  <p>
                    • Type A: 전력사용량 측정 불가(전기 요금이 전력사용량에
                    상관없이 청구되는 사업장)
                  </p>
                  <p>
                    • Type B: 전력사용량 측정 가능(한국전력에 납부하거나
                    관리비에 전력사용량 정보가 있는 사업장)
                  </p>
                </li>
                <li>* 전환율 종류: 전력사용량 기준 50%, 80%, 100%</li>
                <li>
                  * 사무실(Type B), 공장(Type A/B)의 경우 실제 전력사용량에 따라
                  구독료가 달라집니다.
                </li>
                <li className="mailto">
                  * 구독료 및 지원 금액 문의:
                  <a target="_blank" href={`mailto:${link.inquiryEmail}`}>
                    {link.inquiryEmail}
                  </a>
                </li>
              </ul>
              <ul></ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeCalculation;
