import { useCallback, useMemo, useEffect, Children, useState, useRef } from "react";

export default function CarouselPartnerLogo({ children }) {

  const slideBoxRef = useRef();
  const intervalRef = useRef();
  const [current, setCurrent] = useState(0);
  const [translateX, setTranslateX] = useState(0);

  const slides = useMemo(() => {
    if (children.length > 1) {
      let items = Children.map(children, (child, index) => child);
      return [...items];
    }
  }, [current, children]);

  const actionHandler = useCallback(
    (mode) => {
      slideBoxRef.current.style.transitionDuration = "400ms";
      if (mode === "next") {
        if (current >= children.length - 2) {
          setTranslateX(0);
          setCurrent(
            (slideBoxRef.current.clientWidth / children.length) * (current + 1)
          );
          setCurrent(0);
        } else {
          setTranslateX(
            (slideBoxRef.current.clientWidth / children.length) * (current + 1)
          );
          setCurrent((prev) => ++prev);
        }
      }
    },
    [current, children]
  );

  // 무한 슬라이드 효과(부드럽게)
  useEffect(() => {
    const transitionEnd = () => {
      if (current >= children.length - 4) {
        slideBoxRef.current.style.transitionDuration = "0ms";
        setTranslateX(0);
        setCurrent(0);
      }
    };

    document.addEventListener("transitionend", transitionEnd);

    return () => {
      document.removeEventListener("transitionend", transitionEnd);
    };
  }, [current, children]);

  // 자동 슬라이드
  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      actionHandler("next");
    }, 1300);
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [actionHandler]);

  return (
    <div className="slide-box">
      <ul
        ref={slideBoxRef}
        className="home-partners__logos"
        style={{ transform: `translateX(${-translateX}px)` }}
      >
        {slides}
      </ul>
      <button onClick={() => actionHandler("next")} className="next_btn">
        다음
      </button>
    </div>
  );
};