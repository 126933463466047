import {
  useState,
  useCallback,
  useLayoutEffect,
  useEffect,
  useRef,
} from "react";
import "./Banner.css";

export default function Banner() {
  const bannerRef = useRef();
  const containerRef = useRef();
  const intervalRef = useRef(null);

  const [slideTranslateX, setSlideTranslateX] = useState(0);
  const [slideCurrent, setSlideCurrent] = useState(0);

  const handleSlider = (current) => {
    containerRef.current.style.transitionDuration = "700ms";
    setSlideCurrent(current + 1);
    setSlideTranslateX(bannerRef.current.clientWidth * current);
  };

  const handleActionSlider = useCallback(
    (mode) => {
      containerRef.current.style.transitionDuration = "700ms";
      if (mode === "next") {
        if (slideCurrent >= 4) {
          setSlideTranslateX(bannerRef.current.clientWidth * 0);
          setSlideCurrent(1);
        } else {
          // 요기 if문 없을 경우, 딜레이가 6초 일 때 2번째 배너가 12초가 지나가야 발동됨
          if (slideCurrent === 0) {
            setSlideTranslateX(bannerRef.current.clientWidth * 1);
            setSlideCurrent(() => 2);
          } else {
            setSlideTranslateX(bannerRef.current.clientWidth * slideCurrent);
            setSlideCurrent((prev) => ++prev);
          }
        }
      }
    },
    [slideCurrent]
  );

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      handleActionSlider("next");
    }, 6000);
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [handleActionSlider]);

  useLayoutEffect(() => {
    setSlideTranslateX(bannerRef.current.clientWidth * slideCurrent);
  }, []);

  return (
    <section ref={bannerRef} className="home-banner">
      <ul
        ref={containerRef}
        className="image_box"
        style={{ transform: `translateX(${-slideTranslateX}px)` }}
      >
        <li>
          <div className="home-banner__container">
            <div className="home-banner__inner home__inner">
              <div className="home-banner__title banner1 pc">
                <div className="text">
                  <p>건강한 지구를 위한 탄소감축 프로젝트</p>
                  <p>
                    <span className="font_color">재생에너지 </span>구독 서비스
                    <br />
                    월간햇빛바람
                  </p>
                </div>
              </div>
              <div className="home-banner__title banner1 mobile">
                <p>
                  건강한 지구를 위한
                  <br /> 탄소감축 프로젝트
                </p>
                <p>
                  <span className="font_color">
                    재생에너지
                    <br />
                  </span>
                  구독 서비스
                  <br />
                  월간햇빛바람
                </p>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="home-banner__container">
            <div className="home-banner__inner home__inner">
              <div className="home-banner__title banner2 pc">
                <div className="text">
                  <p>더 깨끗하고 착한 재생에너지를 사용할 수 있도록</p>
                  <p>
                    시민들이 만든 친환경
                    <br />
                    <span className="font_color">재생에너지 </span>
                    공급
                  </p>
                </div>
              </div>
              <div className="home-banner__title banner2 mobile">
                <p>
                  더 깨끗하고 착한 재생에너지를
                  <br />
                  사용할 수 있도록
                </p>
                <p>
                  시민들이 만든 친환경
                  <br />
                  <span className="font_color">재생에너지 </span>
                  공급
                </p>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="home-banner__container">
            <div className="home-banner__inner home__inner">
              <div className="home-banner__title banner3 pc">
                <div className="text">
                  <p>더 많은 기업이 재생에너지를 사용할 수 있도록</p>
                  <p>
                    첫 구독 무료 체험 혜택
                    <br />
                    최대 3개월간, 최대 100만원
                    <br />
                    구독료 지원
                  </p>
                </div>
              </div>
              <div className="home-banner__title banner3 mobile">
                <p>
                  더 많은 기업이 재생에너지를 사용해
                  <br />
                  지구를 지킬 수 있도록
                </p>
                <p>
                  첫 구독 무료 체험 혜택
                  <br />
                  최대 3개월간, 최대 100만원
                  <br />
                  구독료 지원
                </p>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="home-banner__container">
            <div className="home-banner__inner home__inner">
              <div className="home-banner__title banner4 pc">
                <div className="text">
                  <p>H-ondream fellow부터 앞장서 재생에너지를 사용하도록</p>
                  <p>
                    현대차정몽구재단
                    <br />
                    그린랩 프로젝트 선정
                  </p>
                </div>
              </div>
              <div className="home-banner__title banner4 mobile">
                <p>
                  H-ondream fellow부터 앞장서
                  <br /> 재생에너지를 사용하도록
                </p>
                <p>
                  현대차정몽구재단
                  <br />
                  그린랩 프로젝트 선정
                </p>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <ul className="pages_box">
        <li
          className={`${
            slideCurrent == 1 || slideCurrent == 0 ? "active" : ""
          }`}
          onClick={() => handleSlider(0)}
        ></li>
        <li
          className={`${slideCurrent == 2 ? "active" : ""}`}
          onClick={() => handleSlider(1)}
        ></li>
        <li
          className={`${slideCurrent == 3 ? "active" : ""}`}
          onClick={() => handleSlider(2)}
        ></li>
        <li
          className={`${slideCurrent == 4 ? "active" : ""}`}
          onClick={() => handleSlider(3)}
        ></li>
      </ul>
    </section>
  );
}
