import { useState, useRef } from "react";
import ModalContainer from "../../../components/Modal/ModalContainer";
import {
  validateOnlyKorean,
  validateEmail,
  validatePhoneNumber,
  validateOnlyNumbers,
} from "../../../utils/validationFn";
import "./SubscribeForm.css";
import axios from "axios";

export default function SubscribeForm() {
  const subscribeRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [networkState, setNetworkState] = useState(true);
  const [isAgreeModal, setIsAgreeModal] = useState(false);

  const [inputValue, setInputValue] = useState({
    companyName: "",
    managerName: "",
    managerEmail: "",
    managerPhone: "",
    officeType: "사무실",
    paymentMethod: "typeA: 측정 불가",
    electricityUsage: "",
    memo: "",
    agree: false,
  });
  const [isEmpty, setIsEmpty] = useState({
    companyName: "",
    managerName: "",
    managerEmail: "",
    managerPhone: "",
    officeType: "",
    paymentMethod: "",
    electricityUsage: "",
    memo: "",
    agree: false,
  });
  const [isValidation, setIsValidation] = useState({
    companyName: "",
    managerName: "",
    managerEmail: "",
    managerPhone: "",
    officeType: "",
    paymentMethod: "",
    electricityUsage: "",
    memo: "",
    agree: "",
  });

  const onChange = (e, name) => {
    setInputValue({
      ...inputValue,
      [name]: e.target.value,
    });
  };

  const handleIsEmpty = (e, name) => {
    setIsEmpty({
      ...isEmpty,
      [name]: true,
    });
  };

  const handleValidationCompanyName = (e, name) => {
    if (!e.target.value) {
      setIsValidation({
        ...isValidation,
        [name]: false,
      });
    } else {
      setIsValidation({
        ...isValidation,
        [name]: true,
      });
    }
  };

  const onClickCheckbox = (e, name) => {
    if (!e.target.checked) {
      setIsEmpty({
        ...isEmpty,
        [name]: true,
      });
    } else {
      setIsEmpty({
        ...isEmpty,
        [name]: false,
      });
    }
  };

  const handleValidationManagerName = (e, name) => {
    validateOnlyKorean(e);
    if (!e.target.value) {
      setIsValidation({
        ...isValidation,
        [name]: false,
      });
    } else {
      setIsValidation({
        ...isValidation,
        [name]: true,
      });
    }
  };

  const handleValidationManagerEmail = (e, name) => {
    setIsValidation({
      ...isValidation,
      [name]: validateEmail(e, name),
    });
  };

  const handleValidationManagerPhone = (e, name) => {
    validatePhoneNumber(e);
    if (!e.target.value) {
      setIsValidation({
        ...isValidation,
        [name]: false,
      });
    } else {
      setIsValidation({
        ...isValidation,
        [name]: true,
      });
    }
  };

  const onChangeCheckbox = (e, name) => {
    if (e.target.checked) {
      setIsValidation({
        ...isValidation,
        [name]: true,
      });
    } else {
      setIsValidation({
        ...isValidation,
        [name]: false,
      });
    }
  };

  const handleValidationPay = (e, name) => {
    validateOnlyNumbers(e);
  };

  window.addEventListener("online", () => {
    setNetworkState(true);
  });
  window.addEventListener("offline", () => {
    setNetworkState(false);
  });

  const handlValidate = () => {
    if (
      isValidation.companyName &&
      isValidation.managerName &&
      isValidation.managerEmail &&
      isValidation.managerPhone &&
      isValidation.agree &&
      networkState
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let form = new FormData();
    form.append("기업 이름", inputValue.companyName);
    form.append("담당자 이름", inputValue.managerName);
    form.append("담당자 이메일", inputValue.managerEmail);
    form.append("담당자 연락처", inputValue.managerPhone);
    form.append("사업장 유형", inputValue.officeType);
    form.append("납부 방식", inputValue.paymentMethod);
    form.append("사용 전기량", inputValue.electricityUsage);
    form.append("메모", inputValue.memo);
    form.append("개인정보 동의", isValidation.agree);

    axios
      .post(
        "https://script.google.com/macros/s/AKfycbwOn7eIKlhjY3XsLJVVS1pR_A6UH1hIl713WnhlvwZrdo2-UPF4OSP16GltpPuPIA5Q/exec",
        form
      )
      .then((res) => {
        console.log("성공");
        setIsSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <section id="subscribe" ref={subscribeRef} className="home-subscribe">
      <div className="home-subscribe__inner home__inner">
        <h2>무료 체험 신청</h2>
        <p className="header-de">
          아래 정보를 입력 후 신청하시면 담당자와 상담이 이루어집니다.
        </p>
        <form
          target="iframe1"
          onSubmit={handleSubmit}
          className="home-subscribe__content__form gform"
        >
          <div>
            <ul>
              <li>
                <label className="label">기업 이름</label>
                <input
                  type="text"
                  name="기업 이름"
                  placeholder="한글, 영문 입력 가능"
                  value={inputValue.companyName}
                  onChange={(e) => onChange(e, "companyName")}
                  onBlur={(e) => handleIsEmpty(e, "companyName")}
                  onInput={(e) => handleValidationCompanyName(e, "companyName")}
                />
                {isEmpty.companyName && (
                  <p
                    className={`empty ${
                      isValidation.companyName ? "" : "show"
                    }`}
                  >
                    기업 이름을 입력해주세요.
                  </p>
                )}
              </li>
              <li>
                <label className="label">담당자 이름</label>
                <input
                  type="text"
                  name="담당자 이름"
                  placeholder="한글 입력 가능"
                  value={inputValue.managerName}
                  onChange={(e) => onChange(e, "managerName")}
                  onBlur={(e) => handleIsEmpty(e, "managerName")}
                  onInput={(e) => handleValidationManagerName(e, "managerName")}
                />
                {isEmpty.managerName ? (
                  <p
                    className={`empty ${
                      isValidation.managerName ? "" : "show"
                    }`}
                  >
                    담당자 이름을 입력해주세요.
                  </p>
                ) : (
                  ""
                )}
              </li>
              <li>
                <label className="label" htmlFor="">
                  담당자 이메일
                </label>
                <input
                  type="text"
                  name="담당자 이메일"
                  placeholder="example@email.com"
                  value={inputValue.managerEmail}
                  onChange={(e) => onChange(e, "managerEmail")}
                  onBlur={(e) => handleIsEmpty(e, "managerEmail")}
                  onInput={(e) =>
                    handleValidationManagerEmail(e, "managerEmail")
                  }
                />
                {isEmpty.managerEmail && (
                  <p
                    className={`empty ${
                      isValidation.managerEmail ? "" : "show"
                    }`}
                  >
                    이메일 형식이 유효하지 않습니다.
                  </p>
                )}
              </li>
              <li>
                <label className="label">담당자 연락처</label>
                <input
                  type="text"
                  name="담당자 연락처"
                  placeholder="숫자 입력 가능"
                  value={inputValue.managerPhone}
                  onChange={(e) => onChange(e, "managerPhone")}
                  onBlur={(e) => handleIsEmpty(e, "managerPhone")}
                  onInput={(e) =>
                    handleValidationManagerPhone(e, "managerPhone")
                  }
                  maxLength={13}
                />
                {isEmpty.managerPhone && (
                  <p
                    className={`empty ${
                      isValidation.managerPhone ? "" : "show"
                    }`}
                  >
                    담당자 연락처를 입력해주세요.
                  </p>
                )}
              </li>
            </ul>
            <ul>
              <li>
                <label className="label">사업장 유형 선택</label>
                <div className="office radio-box">
                  <div>
                    <input
                      id="office"
                      type="radio"
                      name="사업장 유형"
                      value="사무실"
                      onChange={(e) => onChange(e, "officeType")}
                      defaultChecked
                    />
                    <label htmlFor="office">사무실</label>
                  </div>
                  <div>
                    <input
                      id="factory"
                      type="radio"
                      value="공장"
                      name="사업장 유형"
                      onChange={(e) => onChange(e, "officeType")}
                    />
                    <label htmlFor="factory">공장</label>
                  </div>
                </div>
                <h1>
                  *사업장이 2개 이상인 기업은 재생에너지 사용을 원하는 사업장을
                  기준으로 선택해주시면 됩니다.
                </h1>
              </li>
              <li>
                <label className="label">사업장 전기 요금 납부 방식</label>
                <div className="pay radio-box">
                  <div>
                    <input
                      id="indirect"
                      type="radio"
                      value="typeA: 측정 불가"
                      name="납부 방식"
                      onChange={(e) => onChange(e, "paymentMethod")}
                      defaultChecked
                    />
                    <label htmlFor="indirect">
                      <span>Type A: 전력사용량 측정 불가</span>
                      <span className="info">
                        (전력사용량에 상관없이 관리비로 일괄 납부)
                      </span>
                    </label>
                  </div>
                  <div>
                    <input
                      id="direct"
                      type="radio"
                      value="typeB: 측정 가능"
                      name="납부 방식"
                      onChange={(e) => onChange(e, "paymentMethod")}
                    />
                    <label htmlFor="direct">
                      <span>Type B: 전력사용량 측정 가능</span>
                      <span className="info">
                        (한국전력에 납부 or 관리비에 사용량 정보 존재)
                      </span>
                    </label>
                  </div>
                </div>
              </li>
              <li className="kwh">
                <label className="label">지난달 전력사용량(선택)</label>
                <div>
                  <input
                    type="text"
                    name="사용 전기량"
                    placeholder="숫자 입력 가능"
                    value={inputValue.electricityUsage}
                    onChange={(e) => onChange(e, "electricityUsage")}
                    onInput={(e) => handleValidationPay(e, "electricityUsage")}
                  />
                  <p>kWh</p>
                </div>
              </li>
              <li className="textarea">
                <label className="label" htmlFor="">
                  메모(선택)
                </label>
                <textarea
                  name="메모"
                  cols="30"
                  rows="10"
                  value={inputValue.memo}
                  placeholder={`구독관련 추가 문의사항이 있으시면 남겨주세요. 
예) 사업장이 2개인데, 모두 가입하고 싶습니다.`}
                  onChange={(e) => onChange(e, "memo")}
                ></textarea>
              </li>
              <li className="agree">
                <div>
                  <div>
                    <input
                      type="checkbox"
                      name="개인정보 동의"
                      id="agree"
                      onChange={(e) => onChangeCheckbox(e, "agree")}
                      onClick={(e) => onClickCheckbox(e, "agree")}
                    />
                  </div>
                  <label>
                    (필수)
                    <span onClick={() => setIsAgreeModal((prev) => !prev)}>
                      개인정보 수집 이용 동의
                    </span>
                  </label>
                </div>
                {isEmpty.agree && (
                  <p className={`empty ${isValidation.agree ? "" : "show"}`}>
                    개인정보 동의는 필수입니다.
                  </p>
                )}
              </li>
            </ul>
          </div>
          {!networkState && (
            <p className="not-network-msg">
              네트워크에 연결되어 있지 않습니다. 확인 후 신청해주세요.
            </p>
          )}
          <input
            className="btn"
            type={handlValidate() ? "submit" : "button"}
            value={"신청"}
          />
        </form>
        <iframe name="iframe1" style={{ display: "none" }}></iframe>
      </div>
      {isAgreeModal && <AgreeModal setIsAgreeModal={setIsAgreeModal} />}
      {isLoading && <LoadingModal />}
      {isSuccess && <SuccessModal setIsSuccess={setIsSuccess} />}
      {isError && <ErrorModal setIsError={setIsError} />}
    </section>
  );
}

function AgreeModal({ setIsAgreeModal }) {
  return (
    <ModalContainer isShow={true}>
      <div className="modal-content">
        <h1>개인정보 수집 이용 동의</h1>
        <p>
          식스티헤르츠 주식회사는 월간햇빛바람 서비스 제공을 위한 무료 체험
          선정과 고객 상담 등을 위해 개인정보보호법 제15조 등 관련 법규에 의거
          아래와 같이 개인정보를 수집하고 이용합니다. 서비스 이용에 필요한
          최소한의 개인정보 수집 및 이용에 동의하지 않을 수 있으나, 동의를
          거부할 경우 무료 체험 지원이 불가능합니다.
        </p>
        <h2>수집 및 이용 목적</h2>
        <ul>
          <li>• 무료 체험 선정을 위한 고객 상담 접수 및 처리</li>
          <li>• 구독 서비스 제공을 위한 상담 접수 및 처리</li>
          <li>• 계약 이행을 위한 민원 등 고객 고충 처리</li>
          <li>• 중복 상담 확인</li>
        </ul>
        <h2>수집 항목</h2>
        <ul>
          <li>
            • (필수) 기업명, 담당자명, 담당자 이메일, 담당자 연락처, 사업장 유형
          </li>
          <li>• (선택) 사업장 전기요금 납부 방식, 사업장 지난달 전력사용량</li>
        </ul>
        <h2>보유 기간</h2>
        <ul>
          <li>• 수집·이용 동의일로부터 36개월 (단, 요청 시 삭제)</li>
        </ul>
        <button onClick={() => setIsAgreeModal(false)}>X</button>
      </div>
    </ModalContainer>
  );
}

function SuccessModal({ setIsSuccess }) {
  return (
    <ModalContainer>
      <div className="success_modal">
        <p>
          월간햇빛바람 무료 체험 신청을 해주셔서 감사합니다.
          <br />
          빠른 시일내로 담당직원분께 안내드리겠습니다.
        </p>
        <button
          onClick={() => {
            setIsSuccess(false);
            setTimeout(() => {
              window.location.reload();
            }, 100);
          }}
        >
          확인
        </button>
      </div>
    </ModalContainer>
  );
}

function LoadingModal() {
  return (
    <ModalContainer isShow={true}>
      <div className="loading_modal">전송중</div>
    </ModalContainer>
  );
}

function ErrorModal({ setIsError }) {
  return (
    <ModalContainer isShow={true}>
      <div className="error_modal">
        <p>전송에 실패했습니다.</p>
        <p>다시 시도해주세요.</p>
        <button
          onClick={() => {
            console.log("에러 클릭");
            setIsError(false);
          }}
        >
          확인
        </button>
      </div>
    </ModalContainer>
  );
}
