import "./Header.css";
import { HashLink } from "react-router-hash-link";
import { useState } from "react";
import link from "../../data/link";

export default function Header({ isSubscribeActive, isPartnerActive, isReportActive }) {
  const [isShowUnderline, setIsShowUnderline] = useState(false);
  const [isHamShow, setIsHamShow] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 0) {
      setIsShowUnderline(true);
    } else {
      setIsShowUnderline(false);
    }
  });
  const handleHamClick = () => {
    setIsHamShow(!isHamShow);
    if (isHamShow) {
      setIsHamShow(false);
    }
  };
  const handleHamClose = () => {
    if (isHamShow) {
      setIsHamShow(false);
    }
  };
  return (
    <header
      className={`${"header"} ${isShowUnderline ? "show" : ""} ${
        isHamShow ? "hamActive" : ""
      }`}
    >
      <h1 className="blind">월간햇빛바람</h1>
      <div className="header-inner">
        <div className="header__logo-box">
          <a className="header__logo" href={link.connectURL}>
            <img
              src={`${process.env.PUBLIC_URL}/images/monthly_logo.svg`}
              alt="월간햇빛바람"
            />
          </a>
        </div>
        <nav className="pc">
          <ul className="header__nav-menu">
            <li className={`${isReportActive ? "active" : ""}`}>
              <HashLink smooth to="/#calculator">
                구독료 계산기
              </HashLink>
            </li>
            <li className={`${isSubscribeActive ? "active" : ""}`}>
              <HashLink smooth to="/#subscribe">
                무료 체험 신청
              </HashLink>
            </li>
            <li className={`${isPartnerActive ? "active" : ""}`}>
              <HashLink smooth to="/#process">
                구독 원리
              </HashLink>
            </li>
            <li className={`${isReportActive ? "active" : ""}`}>
              <HashLink smooth to="/#report">
                리포트 샘플
              </HashLink>
            </li>
          </ul>
        </nav>
        <nav
          className={`mobile ${isHamShow ? "active" : ""}`}
          onClick={handleHamClick}
        ></nav>
        <ul className={`mobile_menu ${isHamShow ? "active" : ""}`}>
          <li
            className={`${isSubscribeActive ? "active" : ""}`}
            onClick={handleHamClose}
          >
            <HashLink smooth to="/#subscribe">
              무료 체험 신청
            </HashLink>
          </li>
          <li
            className={`${isPartnerActive ? "active" : ""}`}
            onClick={handleHamClose}
          >
            <HashLink smooth to="/#process">
              구독 원리
            </HashLink>
          </li>
          <li
            className={`${isReportActive ? "active" : ""}`}
            onClick={handleHamClose}
          >
            <HashLink smooth to="/#service-detail">
              더 알아 보기
            </HashLink>
          </li>
          <li
            className={`${isReportActive ? "active" : ""}`}
            onClick={handleHamClose}
          >
            <HashLink smooth to="/#report">
              리포트 샘플
            </HashLink>
          </li>
        </ul>
      </div>
    </header>
  );
};
