import { useState } from "react";
import link from "../../../data/link";
import "./MonthlyFee.css";

export default function MonthlyFee() {
  const [isMoreShow, setIsMoreShow] = useState(false);

  const handleMoreShow = () => {
    setIsMoreShow(!isMoreShow);
  };

  return (
    <section className="home-monthly-fee">
      <div className="home-monthly-fee__inner">
        <div
          className={`home__inner home-monthly-fee__title ${
            isMoreShow ? "close" : ""
          }`}
          onClick={handleMoreShow}
        >
          구독료 예시 (VAT 포함)
          {isMoreShow ? (
            <img
              src={`${process.env.PUBLIC_URL}/images/home/minus.png`}
              alt="보기"
            />
          ) : (
            <img
              src={`${process.env.PUBLIC_URL}/images/home/plus.png`}
              alt="닫기"
            />
          )}
        </div>
        <div
          className={`home-monthly-fee__content ${isMoreShow ? "show" : ""}`}
        >
          <img
            className="table-img pc"
            src={`${process.env.PUBLIC_URL}/images/home/monthly_fee_table.png`}
            alt="전환율 금액 표"
          />
          <img
            className="table-img mobile"
            src={`${process.env.PUBLIC_URL}/images/home/monthly_fee_table_mobile.png`}
            alt="전환율 금액 표"
          />
          <div className="text">
            <div>
              <p>
                * 위 금액은 사무실(Type B)의 구독료 예시로, 실제 구독료는 기업의
                전력사용량에 따라 달라집니다.
              </p>
              <p className="mailto">
                * 문의:
                <a target="_blank" href={`mailto:${link.inquiryEmail}`}>
                  {link.inquiryEmail}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
