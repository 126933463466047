import './Benefit.css';

export default function Benefit() {
  return (
    <section className="home-benefit">
      <div className="home__inner">
        <h1>구독 혜택 3가지</h1>
        <ul>
          <li>
            <h2>01</h2>
            <h3>무료 체험 지원</h3>
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/images/home/benefit_1.png`}
                alt="선물 아이콘"
              />
              <p>
                최대 3개월간, 최대 100만원
                <br /> 선착순 지원으로 조기 마감 가능
              </p>
            </div>
          </li>
          <li>
            <h2>02</h2>
            <h3>월간 탄소감축 리포트 제공</h3>
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/images/home/benefit_2.png`}
                alt="문서 아이콘"
              />
              <p>
                구독 성과를 객관적인 수치로
                <br /> 구독 내역을 구체적으로 공유
              </p>
            </div>
          </li>
          <li>
            <h2>03</h2>
            <h3>인증 마크 제공</h3>
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/images/home/benefit_3.png`}
                alt="월간햇빛바람 로고"
              />
              <p>
                재생에너지 사용 홍보 수단
                <br />
                사이트에 삽입해 활용 가능
              </p>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};
