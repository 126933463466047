import "./Process.css";

export default function Process() {
  return (
    <section id="process" className="home-process">
      <div className="home__inner">
        <div className="home-process__title">
          <h2 className="pc">
            재생에너지로 전환을 쉽고 편리하게
            <br />
            신청만 하면 모두 대행
          </h2>
          <h2 className="mobile">
            재생에너지로 전환을 쉽고 편리하게
            <br /> 신청만 하면 모두 대행
          </h2>
        </div>
        <div className="home-process__content">
          <img
            className="pc"
            src={`${process.env.PUBLIC_URL}/images/home/process.png`}
            alt="재생에너지 대행 프로세스"
          />
          <img
            className="mobile"
            src={`${process.env.PUBLIC_URL}/images/home/process_mobile.png`}
            alt="재생에너지 대행 프로세스"
          />
        </div>
      </div>
    </section>
  );
};