import link from "../../data/link";
import "./Footer.css";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-inner">
        <div className="logo">
          <a href={link.sixtyURL} target="_blank">
            <img
              src={`${process.env.PUBLIC_URL}/images/logo_black.png`}
              alt="월간햇빛바람"
            />
          </a>
        </div>
        <div className="content">
          <div className="company">
            <div className="footer__content">
              <h3>식스티헤르츠 주식회사</h3>
              <ul>
                <li>
                  <a href={link.sixtyURL} target="_blank">
                    {link.sixtyURL}
                  </a>
                </li>
                <li>대표 : 김종규</li>
                <li>사업자등록번호: 878-88-01768</li>
                <li>
                  <address>04538 서울시 중구 명동길 73 6층 602호</address>
                </li>
              </ul>
            </div>
          </div>
          <div className="service">
            <h3>패밀리 서비스</h3>
            <ul>
              <li className="map">
                <a href={link.mapURL} target="_blank">
                  햇빛바람지도
                </a>
              </li>
            </ul>
          </div>
          <div className="inquiry">
            <h3>문의</h3>
            <ul>
              <li>
                <a target="_blank" href={`mailto:${link.inquiryEmail}`}>
                  이메일: {link.inquiryEmail}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};
