import { Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home";
import ScrollToTop from "./service/scrollTop";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import Members from "./pages/members/Members";
import companyInfoData from "./data/companyInfo";
import tutorialData from "./data/tutorial";
import { useState } from "react";
import Tutorial from "./pages/Tutorial/Tutorial";

const App = () => {
  const [companyInfo] = useState(companyInfoData);
  const [tutorial] = useState(tutorialData);

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/members/:id"
          element={<Members companyInfo={companyInfo} />}
        />
        <Route
          path="/tutorial/:id"
          element={<Tutorial companyInfo={companyInfo} tutorial={tutorial} />}
        />
        <Route component={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
