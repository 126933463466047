import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "./Tutorial.css";
import { useState } from "react";
import { useParams } from "react-router-dom";
import PageNotFound from "../PageNotFound/PageNotFound";
import link from "../../data/link";

export default function Tutorial({ companyInfo, tutorial }) {
  let { id } = useParams();

  let cIndex = companyInfo.findIndex((el) => {
    return el.id == Number(id);
  });

  let tIndex = tutorial.findIndex((el) => {
    return el.conversionRate == companyInfo[cIndex].conversionRate;
  });

  const htmlCodeContent = [
    {
      id: 1,
      name: "HTML",
      content: `<a class="sixty-re100__badge" href="${link.membersURL}/${companyInfo[cIndex].id}" target="_blank">
    <img class="sixty-re100__badge-image" src="{저장된 이미지 경로 입력}" alt="재생에너지 사용 인증 마크" />
</a>`,
    },
    {
      id: 2,
      name: "REACT",
      content: `<a className="sixty-re100__badge" href="${link.membersURL}/${companyInfo[cIndex].id}" target="_blank">
    <img className="sixty-re100__badge-image" src="{저장된 이미지 경로 입력}" alt="재생에너지 사용 인증 마크" />
</a>`,
    },
    {
      id: 3,
      name: "HTML 가이드",
      content: `  1.  홈페이지에 접속한 사용자가 인증 마크 클릭 시 성과 안내 페이지로 연결될 수 있도록 코드를 작성해 주세요.
  2. [${companyInfo[cIndex].company}]님의 성과 안내 페이지 URL은 "${link.membersURL}/${companyInfo[cIndex].id}"입니다.`,
    },
  ];
  const useTabs = (initialTabs, allTabs) => {
    const [contentIndex, setContentIndex] = useState(initialTabs);
    return {
      contentItem: allTabs[contentIndex],
      contentChange: setContentIndex,
    };
  };
  const { contentItem, contentChange } = useTabs(0, htmlCodeContent);

  const cssCodeContent = [
    {
      id: 1,
      name: "CSS",
      content: `.sixty-re100__badge { z-index: 1000; position: fixed; right: 70px; bottom: 80px; display: block; padding: 20px 25px; border-radius: 7px; background-color: #fff; cursor: pointer; /* 글자 색상 흰색일 경우 배경색은 검은색(background-color: #000), 글자 색상 검정색 일 경우 배경색은 흰색(background-color: #fff) */ }

.sixty-re100__badge-image { width: 180px; height: auto; cursor: pointer; }`,
    },
    {
      id: 2,
      name: "CSS 가이드",
      content: `  1. 만약 HTML 샘플 코드를 사용하지 못하여 클래스 CSS 샘플 코드도 사용하기 어려운 경우엔 CSS 속성 값만 적용해 주시면 됩니다.
  2. 인증 마크 글자 색상에 따라 배경색을 지정해 주셔야 하는데, 글자 색상 흰색일 경우 배경색은 검은색(background-color: #000), 글자 색상 검은색 일 경우 배경색은 흰색(background-color: #fff)로 적용해 주세요.`,
    },
  ];

  const useCssTabs = (initialTabs, allTabs) => {
    const [contentIndex, setContentIndex] = useState(initialTabs);
    return {
      cssContentItem: allTabs[contentIndex],
      cssContentChange: setContentIndex,
    };
  };
  const { cssContentItem, cssContentChange } = useCssTabs(0, cssCodeContent);

  return (
    <>
      {cIndex === -1 ? (
        <PageNotFound />
      ) : (
        <>
          <Header />
          <div className="tutorial">
            <div className="tutorial__inner">
              <section className="tutorial__code-description">
                <h1>
                  재생에너지 사용 인증 마크 삽입하기 (
                  {tutorial[tIndex].conversionRate} 전환 상품)
                </h1>
                <p className="sub-title">
                  월간햇빛바람을 구독하는 경우, 기업 사이트 하단에 재생에너지
                  사용 인증 마크를 삽입할 수 있습니다. 사이트 방문객은 마크를
                  통해 구독 기업이 환경을 해치지 않는 전기를 사용한다는 사실을
                  알 수 있습니다.
                </p>
                <div className="tutorial-image-Box_green">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/tutorial/${tutorial[tIndex].badgeImg}`}
                  />
                </div>
                <div className="tutorial-image-Box_skeleton">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/tutorial/skeleton.png`}
                  />
                </div>
                <p className="sub-title">
                  재생에너지 사용 인증 마크 이미지를 클릭하면 구독 기업의 환경
                  성과 홍보 페이지로 이동합니다. 사이트 방문객에게 구독 기업이
                  재생에너지를 사용해 만들어낸 환경 성과를 구체적으로
                  알려줍니다.
                </p>
                <div className="tutorial-image-Box_page_template">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/tutorial/page_template.png`}
                  />
                  <span>환경 성과 홍보 페이지</span>
                </div>
              </section>
              <section className="tutorial__code">
                <h1>설정 방법</h1>
                <p className="code-sub-title">
                  1단계: 재생에너지 인증 마크 다운로드
                </p>
                <ul>
                  <li>
                    1. 아래의 "{tutorial[tIndex].conversionRate} 상품으로 전환
                    마크 이미지 다운로드" 버튼을 클릭하셔서 마크 이미지 폴더를
                    내려받습니다.
                  </li>
                  <li>
                    2. 내려받은 인증 마크 이미지 중 마음에 드는 이미지를
                    선택하여 프로젝트 내 이미지 폴더에 저장합니다.
                  </li>
                </ul>
                <div className="tutorial-download-Box">
                  <a href={tutorial[tIndex].downloadImg} download>
                    {tutorial[tIndex].conversionRate} 상품으로 전환 마크 이미지
                    다운로드
                  </a>
                </div>
                <div className="tutorial-image-Box_badge_template">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/tutorial/${tutorial[tIndex].totalImg}`}
                  />
                </div>
                <p className="code-sub-title">
                  2단계: 재생에너지 인증 마크 코드 작성
                </p>
                <ul>
                  <li>
                    <ul>
                      <li>1. HTML 샘플 코드를 복사하여 붙여 넣어 주세요.</li>
                      <li>
                        2. {"<img>"} 태그의 src 경로는 위에서 다운 받은 인증
                        마크 이미지가 저장된 경로로 작성해 주세요.
                      </li>
                      <li>
                        3. [{companyInfo[cIndex].company}]님의 성과 안내 페이지
                        URL은 "{link.membersURL}/{companyInfo[cIndex].id}"이며,
                        HTML 샘플 코드에 적용되었습니다.
                      </li>
                      <li className="small-font">
                        *만약 아래의 HTML 샘플 코드를 복사하여 사용할 수 없는
                        경우 "HTML 가이드"를 참고하여 코드를 작성해 주세요.
                      </li>
                    </ul>
                    <div className="tutorial__html wrap">
                      <div className="tutorial__html-code">
                        <p>HTML 샘플 코드</p>
                        <ul className="tutorial__code__tab">
                          {htmlCodeContent.map((list, index) => {
                            return (
                              <li
                                key={list.id}
                                className={
                                  list.name === contentItem.name ? "active" : ""
                                }
                                onClick={() => contentChange(index)}
                              >
                                {list.name}
                              </li>
                            );
                          })}
                        </ul>
                        <pre>{contentItem.content}</pre>
                      </div>
                    </div>
                  </li>
                  <li>
                    <ul>
                      <li>
                        3. css 코드를 복사하여 해당하는 css 파일에
                        붙여넣어주세요.
                      </li>
                      <li className="small-font">
                        *만약 아래의 CSS 샘플 코드를 복사하여 사용할 수 없는
                        경우 "CSS 가이드"를 참고하여 코드를 작성해 주세요.
                      </li>
                    </ul>
                    <div className="tutorial__css wrap">
                      <div className="tutorial__css-code">
                        <p>CSS 샘플 코드</p>
                        <ul className="tutorial__code__tab">
                          {cssCodeContent.map((list, index) => {
                            return (
                              <li
                                key={list.id}
                                className={
                                  list.name === cssContentItem.name
                                    ? "active"
                                    : ""
                                }
                                onClick={() => cssContentChange(index)}
                              >
                                {list.name}
                              </li>
                            );
                          })}
                        </ul>
                        <pre>{cssContentItem.content}</pre>
                      </div>
                    </div>
                  </li>
                </ul>
              </section>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};