// 3자리수 콤마
export const convertThreeDigitComma = (input) => {
  return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

//한글 입력 불가
export const validateNotKorean = (input) => {
  input.target.value = input.target.value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, "");
};

//한글만 입력_나미지 입력 불가
export const validateOnlyKorean = (input) => {
  input.target.value = input.target.value.replace(/[^ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, "");
};

//숫자만 입력_나미지 입력 불가
export const validateOnlyNumbers = (input) => {
  input.target.value = input.target.value.replace(/[^0-9]/g, "");
};

// 전화번호 검사 및 '-' 삽입
export const validatePhoneNumber = (input) => {
  input.target.value = input.target.value
    .replace(/[^0-9]/g, "")
    .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
};

// 이메일 검사
export const validateEmail = (input) => {
  const pattern = /^[a-z0-9_+.-]+@([a-z0-9-]+\.)+[a-z0-9]{2,4}$/;
  const result = pattern.test(input.target.value);
  return result;
};
