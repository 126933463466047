import Footer from "../../components/Footer/Footer";
import link from "../../data/link";
import "./PageNotFound.css";

export default function PageNotFound() {
  return (
    <div className="page-not-found">
      <div className="inner">
        <header>
          <h1 className="blind">월간햇빛바람</h1>
          <a className="logo" href={link.connectURL}>
            <img
              src={`${process.env.PUBLIC_URL}/images/monthly_logo.svg`}
              alt="월간햇빛바람"
            />
          </a>
        </header>
        <main>
          <h2 className="blind">찾을 수 없는 페이지입니다.</h2>
          <div className="excuse-msg__title">
            <p>죄송합니다.</p>
            <p>요청하신 페이지를 찾을 수 없습니다.</p>
          </div>
          <div className="excuse-msg__text">
            <p>
              페이지의 주소가 잘못 입력되었거나, 더 이상 제공되지 않는
              페이지입니다.
            </p>
            <p>입력하신 주소가 정확한지 다시 한번 확인해 주시기 바랍니다.</p>
            <p>
              관련 문의사항은
              <a
                className="service-center"
                target="_blank"
                href={`mailto:${link.inquiryEmail}`}
              >
                월간햇빛바람 메일 문의({link.inquiryEmail})
              </a>
              로 알려주시면 친절하게 안내해 드리겠습니다.
            </p>
            <p>감사합니다.</p>
          </div>
          <div className="to-main-btn">
            <a href={link.connectURL}>메인으로 가기</a>
          </div>
        </main>
      </div>
      <iframe name="iframe1" style={{ display: "none" }}></iframe>
    </div>
  );
};